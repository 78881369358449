<template>
  <div class="payment-detail">
    <div class="detail-content">
      <!-- 基本信息卡片 -->
      <card 
        class="detail-card"
        title="基本信息"
        :head-style="{ fontSize: '14px', minHeight: '40px' }"
        :body-style="{ padding: '0px' }"
      >
        
        <descriptions 
          :column="1" 
          bordered
          :labelStyle="{ width: '100px', textAlign: 'left', padding: '8px 12px' }"
          :contentStyle="{ padding: '8px 12px' }"
        >
          <descriptions-item label="审批编号">
            {{ approvalData.sp_no }}
            <copy-outlined 
              class="copy-icon"
              @click="copyApprovalId(approvalData.sp_no)"
            />
          </descriptions-item>
          <descriptions-item label="申请人">
            {{ getUserDisplayInfo(approvalData.submitter).name }}
          </descriptions-item>
          <descriptions-item label="付款类型">
            {{ approvalData.payment_category }}
          </descriptions-item>
          <descriptions-item label="付款所属部门">
            {{ paymentDepartment.name || '-' }}
          </descriptions-item>
          <descriptions-item label="付款金额">
            {{ approvalData.payment_amount }}元
          </descriptions-item>
          <descriptions-item label="付款事由">
            {{ approvalData.payment_reason }}
          </descriptions-item>
          <descriptions-item label="付款方式">
            {{ approvalData.payment_method }}
          </descriptions-item>
          <descriptions-item label="收款人">
            {{ approvalData.payee_full_name }}
          </descriptions-item>
          <descriptions-item label="银行账号" v-if="approvalData.bank_account_number">
            {{ approvalData.bank_account_number }}
          </descriptions-item>
          <descriptions-item label="开户行" v-if="approvalData.bank_name">
            {{ approvalData.bank_name }}
          </descriptions-item>
          <descriptions-item label="付款日期">
            {{ approvalData.payment_date }}
          </descriptions-item>
          <descriptions-item label="申请时间">
            {{ approvalData.created_at }}
          </descriptions-item>
          <descriptions-item label="备注说明" v-if="approvalData.remark1">
            {{ approvalData.remark1 }}
          </descriptions-item>
          <descriptions-item label="备注说明2" v-if="approvalData.remark2">
            {{ approvalData.remark2 }}
          </descriptions-item>
        </descriptions>
      </card>

      <!-- 审批流程卡片 -->
      <card 
        class="detail-card"
        title="审批流程"
        :head-style="{ fontSize: '14px', minHeight: '40px' }"
        :body-style="{ padding: '16px' }"
      >
        <div class="approval-content">
          <timeline>
            <!-- 申请人节点 -->
            <timeline-item style="padding-bottom: 0;">
              <template #dot>
                <file-text-outlined class="timeline-icon" />
              </template>
              <div class="node-content">
                <div class="node-header">
                  <span class="node-type">申请人</span>
                  <span class="node-dot">•</span>
                  <span class="node-status">已提交</span>
                </div>
                <div class="node-body">
                  <div class="user-info">
                    <avatar 
                      size={32} 
                      :src="getUserDisplayInfo(approvalData.submitter).avatar"
                      style="background-color: #1890ff"
                    >
                      {{ getUserDisplayInfo(approvalData.submitter).name ? getUserDisplayInfo(approvalData.submitter).name.slice(-2) : '' }}
                    </avatar>
                    <span class="node-name">{{ getUserDisplayInfo(approvalData.submitter).name }}</span>
                  </div>
                  <span class="node-time">{{ approvalData.created_at }}</span>
                </div>
              </div>
            </timeline-item>
            
            <!-- 审批流程节点 -->
            <template v-for="(record, index) in spRecords" :key="index">
              <timeline-item 
                v-if="record.approverattr === 1" 
                :class="{ 
                  approved: isApproved(record),
                  pending: isPending(record)
                }"
                style="padding-bottom: 0;"
              >
                <template #dot>
                  <user-outlined class="timeline-icon" />
                </template>
                <template v-for="(detail, detailIndex) in record.details" :key="detailIndex">
                  <div class="node-content">
                    <div class="node-header">
                      <span class="node-type">审批人</span>
                      <template v-if="shouldShowStatus(record, detail)">
                        <span class="node-dot">•</span>
                        <span :class="['node-status', getStatusClass(detail.sp_status)]">
                          {{ getStatusText(detail.sp_status) }}
                        </span>
                      </template>
                    </div>
                    <div class="node-body">
                      <div class="user-info">
                        <avatar 
                          size={32} 
                          :src="getUserDisplayInfo(detail.approver?.userid).avatar"
                          style="background-color: #1890ff"
                        >
                          {{ getUserDisplayInfo(detail.approver?.userid).name ? getUserDisplayInfo(detail.approver?.userid).name.slice(-2) : '' }}
                        </avatar>
                        <span class="node-name">{{ getUserDisplayInfo(detail.approver?.userid).name }}</span>
                      </div>
                      <span class="node-time" v-if="detail.sptime">{{ formatTime(detail.sptime) }}</span>
                    </div>
                  </div>
                </template>
              </timeline-item>
            </template>
            
            <!-- 抄送人节点（合并所有抄送人） -->
            <timeline-item v-if="ccUsers.length > 0" style="padding-bottom: 0;">
              <template #dot>
                <send-outlined class="timeline-icon" />
              </template>
              <div class="node-content">
                <div class="node-header">
                  <span class="node-type">抄送人</span>
                </div>
                <div class="node-body">
                  <div class="cc-users-list">
                    <div v-for="userid in ccUsers" :key="userid" class="cc-user-item">
                      <div class="user-info">
                        <avatar 
                          size={32} 
                          :src="getUserDisplayInfo(userid).avatar"
                          style="background-color: #1890ff"
                        >
                          {{ getUserDisplayInfo(userid).name ? getUserDisplayInfo(userid).name.slice(-2) : '' }}
                        </avatar>
                        <span class="node-name">{{ getUserDisplayInfo(userid).name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </timeline-item>
          </timeline>
        </div>
      </card>

      <!-- 备注信息卡片 -->
      <card 
        v-if="comments.length > 0"
        class="detail-card"
        :title="`备注信息 (${comments.length})`"
        :head-style="{ fontSize: '14px', minHeight: '40px' }"
        :body-style="{ padding: '16px' }"
      >
        <div class="comment-container">
          <div 
            v-for="comment in comments" 
            :key="comment.commentid"
            class="comment-item"
          >
            <div class="comment-header">
              <div class="user-info">
                <avatar 
                  size={32} 
                  :src="getUserDisplayInfo(comment.commentUserInfo.userid).avatar"
                  style="background-color: #1890ff"
                >
                  {{ getUserDisplayInfo(comment.commentUserInfo.userid).name ? getUserDisplayInfo(comment.commentUserInfo.userid).name.slice(-2) : '' }}
                </avatar>
                <span class="node-name">{{ getUserDisplayInfo(comment.commentUserInfo.userid).name }}</span>
              </div>
              <span class="node-time">{{ formatTime(comment.commenttime) }}</span>
            </div>
            <div class="comment-content">
              {{ comment.commentcontent }}
            </div>
            <!-- 如果有附件，显示附件列表 -->
            <div v-if="comment.media_id && comment.media_id.length > 0" class="comment-attachments">
              <div v-for="mediaId in comment.media_id" :key="mediaId" class="attachment-item">
                <paper-clip-outlined />
                <a @click="previewFile(mediaId)">附件</a>
              </div>
            </div>
          </div>
        </div>
      </card>

      <!-- 文件信息卡片 -->
      <card 
        v-if="hasAttachments"
        class="detail-card"
        title="文件信息"
        :head-style="{ fontSize: '14px', minHeight: '40px' }"
        :body-style="{ padding: '16px' }"
      >
        <div class="files-container">
          <!-- 发票列表 -->
          <div v-if="invoiceList.length > 0" class="files-section">
            <div class="section-title">发票文件</div>
            <div class="files-list">
              <div v-for="(invoice, index) in invoiceList" 
                :key="'invoice-' + index"
                class="file-item"
              >
                <div class="file-info">
                  <file-pdf-outlined class="file-icon" />
                  <span class="file-name">发票 {{ index + 1 }}</span>
                </div>
                <a-button type="link" @click="previewFile(invoice)">
                  预览
                </a-button>
              </div>
            </div>
          </div>

          <!-- 附件列表 -->
          <div v-if="attachmentList.length > 0" class="files-section">
            <div class="section-title">附件文件</div>
            <div class="files-list">
              <div v-for="(attachment, index) in attachmentList" 
                :key="'attachment-' + index"
                class="file-item"
              >
                <div class="file-info">
                  <paper-clip-outlined class="file-icon" />
                  <span class="file-name">附件 {{ index + 1 }}</span>
                </div>
                <a-button type="link" @click="previewFile(attachment, true)">
                  预览
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </card>

      <!-- 添加预览对话框 -->
      <el-dialog
        v-model="isShowPreview"
        :title="previewTitle"
        :fullscreen="isMobile"
        :width="dialogWidth"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="pdf-preview-dialog"
        @closed="resetPreviewData"
      >
        <div class="pdf-preview-container" ref="previewContainer">
          <div v-if="isPdf" class="pdf-viewer">
            <vue-pdf-embed
              :source="pdfSource"
              :page="currentPage"
              @loaded="onPdfLoaded"
              class="pdf-content"
            />
          </div>
          <div v-else-if="isImage" class="image-viewer">
            <img :src="fileUrl" alt="Image" class="preview-image" />
          </div>
          <div v-else class="unsupported-file">
            <p>不支持的文件类型或没有可用数据。</p>
          </div>
        </div>
        <div class="pdf-controls">
          <div class="control-group" v-if="isPdf">
            <el-button @click="prevPage" :disabled="currentPage <= 1">
              <el-icon><ArrowLeft /></el-icon>
            </el-button>
            <span class="info">第 {{ currentPage }} / {{ totalPages }} 页</span>
            <el-button @click="nextPage" :disabled="currentPage >= totalPages">
              <el-icon><ArrowRight /></el-icon>
            </el-button>
          </div>
        </div>
        <el-button
          class="download-button"
          type="primary"
          :disabled="!canDownload"
          @click="downloadFile"
        >
          下载
        </el-button>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { 
  FileTextOutlined,
  UserOutlined,
  SendOutlined,
  CopyOutlined,
  PaperClipOutlined,
  FilePdfOutlined
} from '@ant-design/icons-vue'
import api from '@/api'
import * as ww from '@wecom/jssdk'
import {
  Card,
  Descriptions,
  DescriptionsItem,
  Timeline,
  message,
  TimelineItem,
  Avatar
} from 'ant-design-vue'

import { ElLoading } from 'element-plus'

export default {
  name: 'PaymentDetail',
  
  components: {
    FileTextOutlined,
    UserOutlined,
    SendOutlined,
    CopyOutlined,
    Card,
    Descriptions,
    Timeline,
    TimelineItem,
    DescriptionsItem,
    Avatar,
    PaperClipOutlined,
    FilePdfOutlined
  },

  props: {
    approvalId: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const approvalData = ref({})

    const getStatusType = (status) => {
      const statusMap = {
        1: 'warning',   // 审批中
        2: 'success',   // 已通过  
        3: 'danger',    // 已驳回
        4: 'info'       // 已撤销
      }
      return statusMap[status] || 'info'
    }

    const getStatusText = (status) => {
      const statusMap = {
        1: '审批中',
        2: '已通过',
        3: '已驳回', 
        4: '已撤销'
      }
      return statusMap[status] || '未知状态'
    }

    const formatTime = (timestamp) => {
      if (!timestamp) return ''
      const date = new Date(timestamp * 1000)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }

    const paymentDepartment = computed(() => {
      if (!approvalData.value?.payment_department) return {}
      try {
        return JSON.parse(approvalData.value.payment_department)
      } catch (e) {
        console.error('解析付款部门失败:', e)
        return {}
      }
    })

    const spRecords = computed(() => {
      if (!approvalData.value?.sp_record) return []
      try {
        return typeof approvalData.value.sp_record === 'string'
          ? JSON.parse(approvalData.value.sp_record)
          : approvalData.value.sp_record
      } catch (e) {
        console.error('解析审批记录失败:', e)
        return []
      }
    })

    const invoiceList = computed(() => {
      if (!approvalData.value?.invoice_id_list) return []
      try {
        return JSON.parse(approvalData.value.invoice_id_list)
      } catch (e) {
        console.error('解析发票列表失败:', e)
        return []
      }
    })

    const attachmentList = computed(() => {
      if (!approvalData.value?.attachment_id_list) return []
      try {
        return approvalData.value.attachment_id_list
      } catch (e) {
        console.error('解析附件列表失败:', e)
        return []
      }
    })

    const hasAttachments = computed(() => {
      return invoiceList.value.length > 0 || attachmentList.value.length > 0
    })

    const loadApprovalDetail = async () => {
      try {
        const res = await api.payment_detail({ approval_id: props.approvalId })
        if(res.data.code === 200) {
          approvalData.value = res.data.data
        } else {
          message.error(res.data.msg || '获取审批详情失败')
        }
      } catch(err) {
        console.error('获取审批详情失败:', err)
        message.error('获取审批详情失败')
      }
    }

    const isWeWork = computed(() => {
      return /wxwork/i.test(navigator.userAgent)
    })

    const previewFile = async (fileId, isMediaId = false) => {
      const loadingInstance = ElLoading.service()
      try {
        // 检查是否在企业微信环境
        const isWeWork = /wxwork/i.test(navigator.userAgent)
        // 检查是否在移动端企业微信环境
        const isMobileWeWork = isWeWork && /mobile/i.test(navigator.userAgent)
        
        if (isMediaId) {
          // 附件预览
          const res = await api.get_media({ 
            media_id: fileId
          })
          if (res.data.code === 200) {
            if (isMobileWeWork) {
              // 移动端企业微信环境使用 ww.previewFile
              ww.previewFile({
                url: res.data.data.url,
                name: res.data.data.filename || '附件',
                size: res.data.data.size,
                success: (res) => {
                  console.log('预览成功:', res)
                },
                fail: (res) => {
                  console.log('预览失败:', res)
                  message.error('预览失败')
                }
              })
            } else if (isWeWork) {
              // 桌面端企业微信使用 ww.openDefaultBrowser
              ww.openDefaultBrowser({
                url: res.data.data.url,
                success: (res) => {
                  console.log('打开成功:', res)
                  message.success('已跳转到浏览器下载')
                },
                fail: (res) => {
                  console.log('打开失败:', res)
                  message.error('打开失败')
                }
              })
            } else {
              // 其他环境直接打开链接
              window.open(res.data.data.url, '_blank')
            }
          }
        } else {
          // 发票预览
          if (isMobileWeWork) {
            // 移动端企业微信环境
            const res = await api.generate_download_link({ 
              id: fileId
            })
            if (res.data.code === 200) {
              ww.previewFile({
                url: res.data.data.download_url,
                name: res.data.data.file_name,
                size: res.data.data.file_size,
                success: (res) => {
                  console.log('预览成功:', res)
                },
                fail: (res) => {
                  console.log('预览失败:', res)
                  message.error('预览失败')
                }
              })
            }
          } else if (isWeWork) {
            // 桌面端企业微信
            const res = await api.generate_download_link({ 
              id: fileId
            })
            if (res.data.code === 200) {
              ww.openDefaultBrowser({
                url: res.data.data.download_url,
                success: (res) => {
                  console.log('打开成功:', res)
                },
                fail: (res) => {
                  console.log('打开失败:', res)
                  message.error('打开失败')
                }
              })
            }
          } else {
            // 其他环境使用普通预览
            const res = await api.preview({ id: fileId })
            const blob = new Blob([res.data], { 
              type: res.headers['content-type'] 
            })
            const url = URL.createObjectURL(blob)
            window.open(url)
            URL.revokeObjectURL(url)
          }
        }
      } catch (err) {
        console.error('预览失败:', err)
        message.error('预览失败')
      } finally {
        loadingInstance.close()
      }
    }
    const copyApprovalId = (id) => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // 现代浏览器支持 Clipboard API
        navigator.clipboard
          .writeText(id)
          .then(() => {
            message.success('审批编号已复制到剪贴板')
            })
          .catch((err) => {
            console.error('无法复制文本: ', err)
            this.fallbackCopyTextToClipboard(id)
          })
      } else {
        // 不支持 Clipboard API，使用备用方法
        fallbackCopyTextToClipboard(id)
      }
    }

    const fallbackCopyTextToClipboard = (text) => {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // 将 textarea 设为不可见
      textArea.style.position = 'fixed'
      textArea.style.top = 0
      textArea.style.left = 0
      textArea.style.width = '2em'
      textArea.style.height = '2em'
      textArea.style.padding = 0
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'
      textArea.style.background = 'transparent'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        if (successful) {
          message.success('审批编号已复制到剪贴板')
        } else {
          message.warning('复制失败，请手动复制')
        }
      } catch (err) {
        console.error('无法复制文本: ', err)
        message.error('复制失败，请手动复制')
      }
      document.body.removeChild(textArea)
    }

    const getStatusClass = (status) => {
      const statusMap = {
        1: 'warning',
        2: 'success',
        3: 'danger',
        4: 'info'
      }
      return statusMap[status] || 'info'
    }

    // 获取用户显示信息
    const getUserDisplayInfo = (userid) => {
      if (!userid) return { name: '', avatar: '' }
      const userInfo = approvalData.value?.user_info_map?.[userid]
      return {
        name: userInfo?.name || userid,
        avatar: userInfo?.avatar || ''
      }
    }

    const shouldShowStatus = (record, detail) => {
      // 如果当前节点已经有状态（已同意/已驳回），则显示状态
      if (detail.sp_status === 2 || detail.sp_status === 3) {
        return true;
      }
      
      // 如果是审批中状态，需要检查是否是当前正在审批的节点
      if (detail.sp_status === 1) {
        // 检查之前的所有节点是否都已审批完成
        const recordIndex = spRecords.value.indexOf(record);
        for (let i = 0; i < recordIndex; i++) {
          const prevRecord = spRecords.value[i];
          // 如果是抄送节点，跳过
          if (prevRecord.approverattr === 2) continue;
          
          // 检查前面节点的审批状态
          for (const prevDetail of prevRecord.details) {
            // 如果前面有未完成的节点，当前节点不应显示审批中
            if (prevDetail.sp_status !== 2) {
              return false;
            }
          }
        }
        
        // 检查当前记录中，是否是第一个审批中的节点
        const detailIndex = record.details.indexOf(detail);
        for (let i = 0; i < detailIndex; i++) {
          if (record.details[i].sp_status !== 2) {
            return false;
          }
        }
        
        return true;
      }
      
      return false;
    };

    const isApproved = (record) => {
      // 检查是否有任何一个审批人已经审批
      return record.details.some(detail => detail.sp_status === 2 || detail.sp_status === 3);
    };

    const isPending = (record) => {
      return record.details.some(detail => detail.sp_status === 1);
    };

    // 获取所有抄送人
    const ccUsers = computed(() => {
      if (!approvalData.value?.notifyer_list) return [];
      try {
        const notifyerList = typeof approvalData.value.notifyer_list === 'string'
          ? JSON.parse(approvalData.value.notifyer_list)
          : approvalData.value.notifyer_list;
        return Array.from(new Set(notifyerList));
      } catch (e) {
        console.error('解析抄送人列表失败:', e)
        return [];
      }
    });

    const comments = computed(() => {
      if (!approvalData.value?.comments) return []
      try {
        return typeof approvalData.value.comments === 'string'
          ? JSON.parse(approvalData.value.comments)
          : approvalData.value.comments
      } catch (e) {
        console.error('解析备注失败:', e)
        return []
      }
    })

    onMounted(() => {
      loadApprovalDetail()
    })

    return {
      approvalData,
      getStatusType,
      getStatusText,
      formatTime,
      paymentDepartment,
      spRecords,
      invoiceList,
      attachmentList,
      hasAttachments,
      previewFile,
      copyApprovalId,
      getStatusClass,
      getUserDisplayInfo,
      shouldShowStatus,
      isApproved,
      isPending,
      ccUsers,
      comments,
      isWeWork
    }
  }
}
</script>

<style scoped>
/* 修改弹窗容器样式 */
:deep(.el-dialog) {
  height: 80vh; /* 设置固定高度为视口高度的80% */
  margin: 10vh auto 0 !important; /* 上方留出10vh的空间，保持垂直居中 */
  display: flex;
  flex-direction: column;
}

:deep(.el-dialog__body) {
  flex: 1; /* 让内容区域占满剩余空间 */
  overflow: hidden; /* 防止出现双滚动条 */
  padding: 0; /* 移除默认内边距 */
}

.payment-detail {
  height: 100%;
  overflow-y: auto;
  padding: 15px;
}

@media (max-width: 768px) {
  .payment-detail {
    padding: 5px;
  }
}

.detail-content {
  flex: 1;
  /* padding: 16px; */
}

.detail-card {
  margin-bottom: 16px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  :deep(.el-card__header) {
    padding: 12px 16px;
    border-bottom: 1px solid #ebeef5;
  }
  :deep(.el-card__body) {
    padding: 16px;
  }
}

.card-header {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.invoice-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.invoice-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}

.approval-content {
  min-height: 50px;
  padding: 8px 16px;
}

@media (max-width: 768px) {
  .approval-content {
    min-height: 50px;
    padding: 8px;
  }
}

.copy-icon {
  margin-left: 8px;
  color: #1890ff;
  cursor: pointer;
}

.timeline-icon {
  font-size: 16px;
  color: #1890ff;
}

:deep(.ant-timeline-item-head) {
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #e4e7ed;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.ant-timeline-item-tail) {
  border-left: 1px solid #e4e7ed;
}

:deep(.ant-timeline-item-content) {
  padding-left: 16px;
  margin-bottom: 20px;
}

.node-content {
  padding: 0;
  position: relative;
}

.node-header {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
}

.node-header .node-type,
.node-header .node-dot,
.node-header .node-status {
  font-weight: 600;
}

.node-type {
  font-size: 14px;
  color: #333;
}

.node-dot {
  color: #999;
  font-size: 16px;
  line-height: 1;
  margin: 0 2px;
}

.node-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 120px;
}

.node-name {
  font-size: 14px;
  color: #333;
}

.node-time {
  font-size: 12px;
  color: #999;
  flex-shrink: 0;
}

:deep(.ant-descriptions-bordered .ant-descriptions-item-label) {
  width: 100px;
  background-color: #fafafa;
}

:deep(.ant-descriptions-bordered .ant-descriptions-item-content) {
  padding: 12px 16px;
}

.node-status {
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 2px;
  background: #f5f7fa;
}

.node-status.warning {
  color: #E6A23C;
}

.node-status.success {
  color: #67C23A;
}

.node-status.danger {
  color: #F56C6C;
}

.node-separator {
  margin: 0 4px;
  color: #999;
}

:deep(.ant-timeline-item:not(:first-child)) {
  &:not(.approved) {
    .ant-timeline-item-head {
      border-color: #d9d9d9;
    }
    .timeline-icon {
      color: #d9d9d9;
    }
    &:not(.pending) {
      .ant-timeline-item-tail {
        border-left-color: #d9d9d9;
      }
    }
  }
}

/* 抄送人节点样式 */
:deep(.ant-timeline-item:last-child) {
  .timeline-icon {
    color: #1890ff;
  }
  .ant-timeline-item-head {
    border-color: #1890ff;
  }
  .ant-timeline-item-tail {
    border-left-color: #1890ff;
  }
}

.cc-users-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.cc-user-item {
  display: flex;
  align-items: center;
}

.cc-user-item .user-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 修改 timeline item content 的左边距 */
:deep(.ant-timeline-item-content) {
  padding-left: 12px;  /* 这里可以根据需要调整具体的数值 */
}

/* 如果需要在移动端使用不同的左边距，可以添加媒体查询 */
@media (max-width: 768px) {
  :deep(.ant-timeline-item-content) {
    padding-left: 8px;  /* 移动端可以使用更小的左边距 */
  }
}

/* 让模态框标题居中 */
:deep(.ant-modal-title) {
  text-align: center;
}

/* 如果需要调整标题的其他样式,也可以在这里设置 */
:deep(.ant-modal-header) {
  padding: 16px 24px;
  border-bottom: 1px solid #f0f0f0;
}


.comment-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-item {
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.comment-content {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: break-all;
  padding-left: 40px;
}

.comment-attachments {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-left: 40px;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #1890ff;
  cursor: pointer;
}

.attachment-item:hover {
  color: #40a9ff;
}

@media (max-width: 768px) {
  .comment-item {
    padding: 12px;
  }
  
  .comment-content,
  .comment-attachments {
    padding-left: 40px;
  }
}

/* 添加文件相关样式 */
.files-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.files-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.section-title {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.files-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #fafafa;
  border-radius: 4px;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.file-icon {
  font-size: 16px;
  color: #1890ff;
}

.file-name {
  font-size: 14px;
  color: #333;
}

/* 预览对话框样式 */
.pdf-preview-dialog {
  display: flex;
  flex-direction: column;
}

.pdf-preview-container {
  height: calc(80vh - 120px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.pdf-viewer,
.image-viewer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-content {
  width: 100%;
  height: auto;
}

.preview-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.pdf-controls {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  z-index: 1;
}

.control-group {
  display: flex;
  align-items: center;
  margin: 5px;
}

.info {
  min-width: 80px;
  text-align: center;
  margin: 0 5px;
  font-size: 12px;
  font-weight: bold;
}

.download-button {
  position: absolute;
  top: 15px;
  right: 15px;
}

@media (max-width: 768px) {
  .pdf-controls {
    flex-wrap: wrap;
  }
  
  .control-group {
    width: 100%;
    justify-content: center;
    margin: 5px 0;
  }

  .download-button {
    top: 10px;
    right: 10px;
  }
}
</style>